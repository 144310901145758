import React, { FC, MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { Color } from 'utils/theme';

import { Text } from 'components';
import { smMobile } from 'styles/breakpoints';

interface ButtonProps {
  color?: Color;
  bgColor?: Color;
  shadow?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  secondaryText?: string;
  margin?: string;
}

interface ButtonStyledProps {
  bgColor: Color;
  shadow: boolean;
  margin?: string;
}

const ButtonStyled = styled.button<ButtonStyledProps>`
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  border-radius: 62.5rem;
  min-height: 3rem;
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
  box-shadow: ${({ shadow }) =>
    shadow
      ? '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)'
      : 'none'};
  max-width: 21.4375rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ margin }) => margin};
  @media ${smMobile} {
    max-width: 18rem;
  }
`;

const SecondaryText = styled(Text)`
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

const Button: FC<ButtonProps> = ({
  color = 'light0',
  bgColor = 'green',
  shadow = true,
  disabled,
  children,
  secondaryText,
  onClick,
  margin = '0',
  ...props
}) => (
  <ButtonStyled
    bgColor={bgColor}
    shadow={shadow}
    disabled={disabled}
    onClick={onClick}
    margin={margin}
    {...props}
  >
    <Text type="body700" color={color} textAlign="center">
      {children}
    </Text>
    {secondaryText ? <SecondaryText>{secondaryText}</SecondaryText> : null}
  </ButtonStyled>
);

export default Button;
